import React from "react"
import styled from "styled-components"
import device from "./device"
import { useSpring, animated } from "react-spring"
import "intersection-observer" // optional polyfill
import { useInView } from "react-intersection-observer"

const Heading = styled(animated.div)`
  font-size: ${props => props.size};
  display: inline-block;
  text-transform: none;
  color: var(--green);
  font-family: "Great Vibes", cursive;
  font-style: normal;
  ${device.large`font-size: 6.5rem;`}
`

export default function GreenHeading({ text, size = "5rem", animate = true }) {
  const [ref, inView] = useInView()
  const spring = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translate3d(0,0,0)" : "translate3d(0,50px,0)",
  })

  return (
    <Heading size={size} style={animate ? spring : null} ref={ref}>
      {text}
    </Heading>
  )
}
